
import { Notification } from "../../utils/Notifications/Notification";
import { NOTIFICATION_TYPE } from "../../utils/settings/config";
import { CLEAR_EXPORT, RESET_DON_SHIP, SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP, SET_DANH_SACH_DON_SHIP, SET_DANH_SACH_DON_VI_VAN_CHUYEN, SET_DANH_SACH_TINH_TRANG_DON_SHIP, SET_DON_SHIP_EDIT, THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP, THEM_VAO_EXPORT, XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP, XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP, XOA_KHOI_EXPORT } from "../types/DonShipType";
import { SET_DANH_SACH_ORDER, SET_ORDER_EDIT, } from "../types/OderType";

const initialState = {
    danhSachDonShip: [],
    totalCount: 0,
    danhSachDonHangDaChon: [],
    danhSachDonHang: [],
    danhSachTinhTrangDonHang: [
        {
            "Id": 5,
            "Ten": "Hàng đã về, đợi ship"
        },
        {
            "Id": 8,
            "Ten": "Trả hàng"
        },
    ],
    donShipEdit: {},
    totalCountDanhSachDonHang: 0,
    danhSachDonViVanChuyen: [],
    danhSachTinhTrangDonShip: [],
    danhSachDonShipExport: [],
}

export const DonShipReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DANH_SACH_DON_SHIP: {
            state.danhSachDonShip = action.danhSachDonShip;
            state.totalCount = action?.totalCount;
            return { ...state }
        }

        case THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP: {
            const { payload } = action;
            const { danhSachDonHangDaChon } = state;

            if (danhSachDonHangDaChon.length === 0 ||
                danhSachDonHangDaChon[0].IdKhachHang === payload.IdKhachHang) {

                const updatedDanhSach = [...danhSachDonHangDaChon, payload];

                // Sort the list by Id in ascending order
                updatedDanhSach.sort((a, b) => a.Id - b.Id);

                return {
                    ...state,
                    danhSachDonHangDaChon: updatedDanhSach,
                };
            } else {
                Notification(NOTIFICATION_TYPE.error, "không thể thêm đơn hàng của khách hàng khác");
                // Return the current state without any changes if IdKhachHang doesn't match
                return state;
            }
        }

        case THEM_VAO_EXPORT: {
            const { payload } = action;
            const { danhSachDonShipExport } = state;
            const updatedDanhSach = [...danhSachDonShipExport, payload];

            updatedDanhSach.sort((a, b) => -a + b);

            return {
                ...state,
                danhSachDonShipExport: updatedDanhSach
            }
        }

        case XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP:
            return {
                ...state,
                danhSachDonHangDaChon: state.danhSachDonHangDaChon.filter(
                    (order) => order.Id !== action.payload.Id
                ),
            };

        case XOA_KHOI_EXPORT:
            return {
                ...state,
                danhSachDonShipExport: state.danhSachDonShipExport.filter(
                    (Id) => Id !== action.payload
                ),
            };

        case XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP:
            return {
                ...state,
                danhSachDonHangDaChon: [],
            };

        case CLEAR_EXPORT: {
            return {
                ...state,
                danhSachDonShipExport: []
            }
        }

        case SET_DON_SHIP_EDIT: {
            state.danhSachDonHangDaChon = action?.danhSachDonHangDaChon?.sort((a, b) => a.Id - b.Id);
            state.donShipEdit = action?.donShipEdit;

            return { ...state }
        }

        case RESET_DON_SHIP: {
            state.danhSachDonHangDaChon = [];
            state.donShipEdit = {}
            return { ...state }
        }

        case SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP: {
            state.danhSachDonHang = action?.danhSachDonHang;
            state.totalCountDanhSachDonHang = action?.totalCountDanhSachDonHang;
            return { ...state }
        }

        case SET_DANH_SACH_DON_VI_VAN_CHUYEN: {
            state.danhSachDonViVanChuyen = action?.danhSachDonViVanChuyen;
            return { ...state }
        }

        case SET_DANH_SACH_TINH_TRANG_DON_SHIP: {
            state.danhSachTinhTrangDonShip = action?.danhSachTinhTrangDonShip;
            return { ...state }
        }

        default:
            return state
    }
}
