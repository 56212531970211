import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  Tooltip,
} from "antd";
import { debounce } from "lodash";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { history } from "../../utils/constants/History";
import {
  CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA,
  CLEAR_EXPORT,
  LAY_CHI_TIET_DON_SHIP_SAGA,
  LAY_CHI_TIET_DON_SHIP_SAGA_V2,
  LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA,
  LAY_DANH_SACH_DON_SHIP_SAGA,
  LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA,
  LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA,
  THEM_VAO_EXPORT,
  XOA_DON_SHIP_SAGA,
  XOA_KHOI_EXPORT,
} from "../../redux/types/DonShipType";
import ShippingOrderPrint from "../../components/ShippingOrderPrint";
import { donShipService } from "../../services/donShipService";
import { DISPLAY_LOADING, HIDE_LOADING } from "../../redux/types/LoadingType";
import { NOTIFICATION_TYPE, STATUS_CODE } from "../../utils/settings/config";
import { Notification } from "../../utils/Notifications/Notification";

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

export default function DanhSachDonShip(props) {
  const dispatch = useDispatch();

  // const [selectedOrders, setSelectedOrders] = useState([]);
  const [exportTemplate, setExportTemplate] = useState("bee");

  const {
    totalCount,
    danhSachDonShip,
    danhSachTinhTrangDonShip,
    danhSachDonViVanChuyen,
    danhSachDonShipExport,
  } = useSelector((state) => state.DonShipReducer);

  const [filters, setFilters] = useState({
    maVanDon: "",
    startDate: null,
    endDate: null,
    idDonViVanChuyen: "",
  });

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA });

    dispatch({ type: LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA });
  }, []);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const handleSearch = () => {
    setPagination({ ...pagination, current: 1 }); // Reset về trang 1 khi tìm kiếm mới
    dispatch({
      type: LAY_DANH_SACH_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Đơn Ship");
  };

  const [selectedOrder, setSelectedOrder] = useState(null);
  const printRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { danhSachDonHangDaChon } = useSelector(
    (state) => state?.DonShipReducer
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (
      isPrinting &&
      selectedOrder &&
      danhSachDonHangDaChon?.length > 0 &&
      isDataLoaded
    ) {
      handlePrint();
      setIsPrinting(false);
    }
  }, [isPrinting, selectedOrder, danhSachDonHangDaChon, isDataLoaded]);

  const handlePrintClick = (record) => {
    setSelectedOrder(record);
    setIsDataLoaded(false);
    dispatch({
      type: LAY_CHI_TIET_DON_SHIP_SAGA_V2,
      id: record?.Id,
      callback: () => {
        setIsDataLoaded(true);
      },
    });
    setIsPrinting(true);
  };

  const handleSelectedDonShip = (record) => {
    const exists = danhSachDonShipExport.find((Id) => Id === record.Id);
    if (exists) {
      dispatch({ type: XOA_KHOI_EXPORT, payload: record?.Id });
    } else {
      dispatch({ type: THEM_VAO_EXPORT, payload: record?.Id });
    }
  };

  const handleExportExcel = async () => {
    if (danhSachDonShipExport.length === 0) {
      message.warning("Vui lòng chọn ít nhất một đơn hàng để xuất Excel!");
      return;
    }
    dispatch({ type: DISPLAY_LOADING });
    let response;
    try {
      response = await donShipService.exportDonShipExcelAPI(
        exportTemplate,
        danhSachDonShipExport
      );
      dispatch({ type: HIDE_LOADING });

      if (response?.status !== STATUS_CODE.SUCCESS) {
        console.log(response);
        Notification(
          NOTIFICATION_TYPE.error,
          "export file thất bại",
          response?.data?.message
        );
        return;
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADING });

      Notification(
        NOTIFICATION_TYPE.error,
        "export file thất bại",
        error?.response?.data?.message
      );
      return;
    }

    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const tempFileName = `DonShip_${timestamp}.xlsx`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", tempFileName); // Đặt tên file tải xuống
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      title: "Chọn",
      key: "select",
      render: (text, record) => (
        <Checkbox
          checked={!!danhSachDonShipExport.find((Id) => Id === record.Id)}
          onChange={() => handleSelectedDonShip(record)}
        />
      ),
      width: 50,
    },
    {
      title: "STT",
      key: "index",
      render: (text, record, index) => {
        return (pagination.current - 1) * pagination.pageSize + index + 1;
      },
      width: 10,
    },
    {
      title: "Mã",
      dataIndex: "MaDonShipNoiBo",
      key: "MaDonShipNoiBo",
    },
    // {
    //   title: " Mã vận đơn",
    //   dataIndex: "MaVanDon",
    //   key: "MaVanDon",
    // },
    {
      title: "Đơn vị vận chuyển",
      dataIndex: "TenDonViVanChuyen",
      key: "TenDonViVanChuyen",
      render: (text, record) => {
        return (
          <>
            <div>
              Mã : <span className="fw-bold">{record?.MaVanDon}</span>
            </div>
            <div>{text}</div>
          </>
        );
      },
    },
    {
      title: "Số Lượng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              SĐ: <span className="fw-bold">{record?.SoDon}</span>
            </div>
            <div>
              SP: <span className="fw-bold">{record?.SoSanPham}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Tiền",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              COD:{" "}
              <span className="fw-bold">
                {record?.TienCOD?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                }).replace(/\./g, ",")}
              </span>
            </div>
            <div>
              Phí:{" "}
              <span className="fw-bold">
                {record?.PhiShip?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                }).replace(/\./g, ",")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            {record?.tbKhachHang.LinkFacebook ? (
              <a target="_blank" href={record?.tbKhachHang?.LinkFacebook}>
                <span style={{ fontWeight: "bold", color: "inherit" }}>
                  {record?.tbKhachHang?.TenKhachHang}
                </span>
              </a>
            ) : (
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.tbKhachHang?.TenKhachHang}
              </span>
            )}
            {" ("}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.tbKhachHang?.SoDienThoai}
            </span>
            {") "}
          </div>
          <div style={{ fontWeight: "bold", color: "inherit" }}>
            {record?.DiaChiNhanHang}
          </div>
        </div>
      ),
    },
    {
      title: "Ngày",
      dataIndex: "NgayShip",
      key: "NgayShip",
      render: (text, record) => {
        const NgayShip = record?.NgayShip
          ? dayjs(record?.NgayShip).format("DD/MM/YYYY")
          : "";

        const ngayTao = record?.NgayTao
          ? dayjs(record?.NgayTao).format("DD/MM/YYYY")
          : "";
        return (
          <>
            <div>
              Tạo: <span className="fw-bold">{ngayTao}</span>
            </div>
            <div>
              Ship: <span className="fw-bold">{NgayShip}</span>
            </div>
          </>
        );
      },
    },

    {
      title: "Tình Trạng",
      dataIndex: "TinhTrangDonShip",
      key: "TinhTrangDonShip",
      render: (text, record) => {
        return (
          <>
            <div>
              <span>{text}</span>{" "}
              <Button
                size="small"
                type="primary"
                shape="circle"
                onClick={() => {
                  let idTrangThai = 2;
                  confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <>
                        <h6>Chọn Trạng Thái Đơn Ship {record?.MaVanDon}</h6>
                        <Select
                          style={{ width: "100%" }}
                          defaultValue={idTrangThai}
                          placeholder="Tình trạng Đơn Ship"
                          onChange={(value) => {
                            idTrangThai = value;
                          }}
                        >
                          {danhSachTinhTrangDonShip?.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item?.Id}>
                                {item?.Ten}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        <h6 className="mt-2 text text-warning">
                          Bạn có chắc chắn muốn thay đổi trạng thái đơn Ship{" "}
                          {record?.MaDonShipNoiBo} không?
                        </h6>
                      </>
                    ),
                    onOk() {
                      dispatch({
                        type: CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA,
                        idDonShip: record?.Id,
                        idTrangThai,
                      });
                    },
                    onCancel() {
                      //   console.log("Cancel");
                    },
                  });
                }}
              >
                <Tooltip title="Chỉnh Sửa Tình Trạng ship">
                  <i className="fa-solid fa-pen-to-square"></i>
                </Tooltip>
              </Button>
            </div>
            <div>
              <span className="fw-bold">{record?.TenNguoiTao}</span>
            </div>
          </>
        );
      },
    },

    {
      title: "Chức năng",
      key: "Select",
      render: (text, record) => {
        return (
          <>
            <>
              <div className="row">
                <button
                  className="btn me-2 btn-primary col-6"
                  onClick={() => {
                    history.push(`/giao-hang/chinh-sua-don-ship/${record?.Id}`);
                  }}
                >
                  <Tooltip title="Chỉnh Sửa Đơn Ship">
                    <FormOutlined style={{ fontSize: 17 }} />
                  </Tooltip>
                </button>
                <Popconfirm
                  title="Xóa Đơn Ship"
                  description={`Bạn có chắc chắn muốn xóa Đơn Ship "${record?.MaDonShipNoiBo}"?`}
                  onConfirm={() => {
                    dispatch({
                      type: XOA_DON_SHIP_SAGA,
                      id: record?.Id,
                    });
                  }}
                  onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                >
                  <button className="btn btn-danger me-2 col-6">
                    <Tooltip title="Xóa Đơn Ship">
                      <i className="fa-solid fa-trash"></i>
                    </Tooltip>
                  </button>
                </Popconfirm>

                <Button
                  className="col-6"
                  onClick={() => {
                    // dispatch({
                    //   type: LAY_CHI_TIET_DON_SHIP_SAGA,
                    //   id: record?.Id,
                    // });
                    handlePrintClick(record);
                  }}
                >
                  <Tooltip title="In Đơn Ship">
                    <i className="fa-solid fa-print"></i>
                  </Tooltip>
                </Button>
              </div>
            </>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Danh Sách Giao Hàng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/giao-hang/them_moi_don_ship");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Đơn Ship
        </button>
      </div>

      <div className="search-filters my-4 row">
        <div className="col-md-4">
          <h6>Chọn Đơn Vị Vận Chuyển</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.idDonViVanChuyen}
            onChange={(value) =>
              setFilters({ ...filters, idDonViVanChuyen: value })
            }
            placeholder="Chọn Đơn Vị Vận Chuyển"
          >
            {danhSachDonViVanChuyen?.map((item, key) => (
              <Select.Option key={key} value={item?.Id}>
                {item?.DonViVanChuyen}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-4 ">
          <h6>Lọc theo Ngày Tạo</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>

        <div className="col-md-2">
          <h6>Mã Vẫn Đơn</h6>
          <Input
            value={filters.maVanDon}
            onChange={(e) =>
              setFilters({ ...filters, maVanDon: e.target.value })
            }
            placeholder="Nhập Mã Nhận Đơn"
          />
        </div>

        <div className="col-md-2 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>
      {danhSachDonShipExport.length !== 0 ? (
        <div className="row mb-3">
          <div className="col-md-3"></div>
          <Select
            style={{ padding: "2px" }}
            className="col-md-2"
            value={exportTemplate}
            onChange={(value) => setExportTemplate(value)}
          >
            <Option value="bee">Mẫu Bee</Option>
            <Option value="best">Mẫu Best</Option>
          </Select>
          <Button
            type="primary"
            style={{ padding: "2px" }}
            className="ms-md-3 col-md-3"
            onClick={handleExportExcel}
            disabled={danhSachDonShipExport.length === 0}
          >
            Xuất Excel <i className="fa fa-download"></i>
          </Button>
          <button
            onClick={() => {
              dispatch({ type: CLEAR_EXPORT });
            }}
            className="ms-md-2 btn btn-warning col-md-3"
          >
            Hủy Chọn Tất cả <i className="fa fa-times-circle"></i>
          </button>
        </div>
      ) : (
        <></>
      )}

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachDonShip}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
        onChange={handleTableChange}
      />
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <ShippingOrderPrint order={selectedOrder} />
        </div>
      </div>
    </div>
  );
}
